.section-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}

.section-content {
    position: relative;
    padding: 12px;
}

.section-content:before,
.section-content:after {
    content: "";
    display: block;
    position: absolute;
    width: 50vw;
    border-bottom: 1px solid;
    top: 50%;
}
.section-content:before {
    right: 100%;
}
.section-content:after {
    left: 100%;
}
