#LoginScreen {
    height: 100%;
    align-items: center;
    justify-content: center;
}

#LoginScreen #content {
    max-width: auto;
    text-align: center;
}

#LoginScreen button {
    background-color: #4285F4;
    font-size: 2em;
}