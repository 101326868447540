
#status-bar {
    border-bottom: 1px solid #ccc;
    box-shadow: 2px 0px 8px #00000099;
}

#ContextMenu {
    background: #fff;
    box-shadow: 2px 2px 4px #00000044;
    position: absolute;
    width: 200px;
    min-height: 20px;
    z-index: 99999;
    padding: 4px 8px;
}

#ContextMenu a {
    display: block;
    margin-bottom: 4px;
    color: #333;
}

.icon-selection {
    font-size: 18px;
    color: #333;

    span {
        display: inline-block;
        overflow: hidden;
        border-radius: 50%;
        background-color: #eee;
        margin: 3px;
        border: 5px solid #fff;
    }

    .active {
        background: #fff;
        border-color: green;
    }

    svg, i {
        margin: 0.35em;
    }
}