.Loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.LoadingContent {
    font-size: 30px;
    padding: 12px;
    text-align: center;
}