.projects {
}

.project {
    a {
        font-size: 24px;
    }
}

.project-link {
    display: block;
    color: #333;
}
