html,
body,
#root {
    font-family: $font-family-serif;
    padding: 0;
    margin: 0;
    height: 100%;
}

a {
    text-decoration: none;
}

.App {
    height: 100%;
    display: flex;
}

#left-pane {
    padding-right: 0px;

    .btn {
        display: block;
        text-align: left;
        margin: 3px 0px;
    }

    .list-item a {
        display: block;
        padding: 2px 2px;
        cursor: pointer;
        margin-top: 0px;
        color: var(--gray-dark);
        white-space: nowrap;
    }

    a.active {
        background-color: #00000044;
    }

    a.drag-over {
        background-color: #00aaaa44;
    }

    .list-item-children {
        margin-left: 26px;
        border-left: 1px solid #aaa;
    }

    #left-pane-main {
        flex: 1;
    }

    #left-pane-bottom {
        padding: 8px;
    }

    #left-pane-header {
        font-weight: bold;
        border-bottom: 2px solid #bbb;
    }
}

#right-pane {
    position: relative;
    display: flex;
    flex-direction: column;
    #right-pane-main {
        padding: 0 1rem;
        overflow-y: scroll;
        p {
            text-indent: 1em;
            margin-bottom: 0;
        }
    }
}

#left-pane-resize,
#right-pane-resize {
    display: block;
    position: absolute;
    top: 0px;
    height: 100%;
    width: 5px;
    cursor: ew-resize;
}

#left-pane-resize {
    border-right: 1px solid #ccc;
    right: 0px;
}

#right-pane-resize {
    border-left: 1px solid #ccc;
    left: 0;
}

#content-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

#project-nav-items {
    margin-bottom: 1em;
}

.project-nav-controls {
    padding-right: 16px;
}

#main-pane {
    flex: 1;
    min-width: 250px;
}

#content {
    flex: 1;
    height: 100%;
}

/* Mobile */
@media (max-width: map-get($grid-breakpoints, md)) {
    #root {
        font-size: 14px;
    }
    .App {
        flex-direction: column;
    }
    #navbar {
        width: 100%;
        z-index: 9999;
    }
    #left-pane {
        width: 90% !important;
        position: absolute;
        top: 56px;
        left: 0;
        z-index: 9999;
        display: none;

        &.show {
            display: block !important;
        }

        #left-pane-main {
            overflow-x: scroll;
        }
    }

    #content-wrapper {
        height: 100%;
    }

    #main-pane {
        flex: 1 1 auto;
    }

    #right-pane {
        display: none !important;
    }
}

/* Desktop */
@media (min-width: map-get($grid-breakpoints, md)) {
    html,
    body,
    #root {
        height: 100%;
    }
    .App {
        flex-direction: row;
    }
    #navbar {
        display: none;
    }
    #left-pane {
        height: 100%;
        width: 250px;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        position: relative;
    }
    #left-pane-main {
        height: 100%;
        overflow-y: scroll;
    }

    #content-wrapper {
        height: 100vh;
    }

    .c2 {
        column-count: 2;
        column-gap: 2rem;
    }

    .c2 > * {
        -webkit-column-break-inside: avoid;
    }
}



.btn-xl {
    padding: 0.75rem 1.15rem;
    font-size: 1.5rem;
    border-radius: 0.5rem;
}

/** Scrollbar **/
/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
/** End Scrollbar **/

.table-responsive {
    white-space: nowrap;
}
