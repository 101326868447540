.editor {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    .editor-content {
        flex: 1 1 auto !important;
        height: 400px; /** This is not used due to flex **/
        overflow-y: scroll;
        .ProseMirror {
            min-height: 100%;
            padding: 1em 1em 2em 1em;
        }
    }
    p {
        text-indent: 1em;
        margin-bottom: 0;
    }

    li p {
        text-indent: 0em;
        margin-bottom: 0.2rem;
    }
}

#content-wrapper .word-count {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 0.3em;
    background-color: #fff;
    /* Desktop */
    @media (min-width: map-get($grid-breakpoints, md)) {
        position: absolute;
    }
}

.btn-toolbar {
    .btn {
        border-radius: 0 !important;
        @media (max-width: map-get($grid-breakpoints, md)) {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
}

.tip-tap-toolbar-container {
    min-height: 40px;
}
.tip-tap-toolbar {
    position: relative;
    top: 0px;
    background-color: white;
    z-index: 99;
    border-bottom: 1px solid #ddd;

    .btn-group {
        border-right: 1px solid #ddd;
        padding: 0 0.5em;
    }
}

.fixed-toolbar #toolbar {
    position: fixed;
}

.tableWrapper {
    overflow-x: auto;
}

.tableWrapper table,
.tableWrapper th,
.tableWrapper td {
    border: 1px solid #000;
}

.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}

blockquote {
    border-left: 3px solid #ddd;
    padding-left: 0.5em;
}
